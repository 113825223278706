<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-header`">
      <div  :class="`${prefixCls}-header-carousel`">
        <Carousel :carouselData="swipeList" height="600px">
        </Carousel>
      </div>
    </div>
    <div :class="`${prefixCls}-content`">
      <ContactWay />
      <message-board />
    </div>
  </div>
</template>

<script>
import Carousel from '../../components/Carousel/index.vue'
import ContactWay from './components/contactWay.vue'
// import { getPartner } from '../../api/partner'
import { getBannerByChannelId } from '@/api'
import MessageBoard from './components/MessageBoard.vue'
export default {
  name: 'Partner',
  data () {
    return {
      prefixCls: this.$prefix + '-partner',
      partnerData: {},
      swipeList: []
    }
  },
  created () {
    // getPartner().then(res => {
    //   this.partnerData = res.data.data
    // })
    this.getBannerByChannelId()
  },
  components: {
    MessageBoard,
    Carousel,
    ContactWay
  },
  methods: {
    getBannerByChannelId () {
      getBannerByChannelId(8).then(({ data }) => {
        this.swipeList = data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -partner;
.#{$prefixCls}{
  width: 100%;
  &-header{
    width: 100%;
    overflow: hidden;
    position: relative;
    color: #fff;
    &-carousel{
      width: 100%;
    }
    &-text{
      position: absolute;
      left: 15%;
      top: 200px;
      &-title{
        font-size: 72px;
      }
      &-en{
        font-size: 28px;
        text-align: left;
        font-weight: 400;
        font-family: SourceHanSansCN-Light;
        margin-bottom: 40px;
      }
      &-desc{
        font-size: 25px;
        text-align: left;
      }
    }
    &-img{
      width: 40%;
      min-width: 500px;
      position: absolute;
      bottom: -80px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
