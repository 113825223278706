
import request from '../utils/axios/index'

export function siteLeaveAdd (data) {
  return request({
    url: '/rest/siteLeave/add',
    method: 'post',
    data
  })
}
