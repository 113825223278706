import { render, staticRenderFns } from "./contactWay.vue?vue&type=template&id=252e2c55&scoped=true&"
import script from "./contactWay.vue?vue&type=script&lang=js&"
export * from "./contactWay.vue?vue&type=script&lang=js&"
import style0 from "./contactWay.vue?vue&type=style&index=0&id=252e2c55&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252e2c55",
  null
  
)

export default component.exports