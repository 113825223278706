<template>
  <div class="message-board-container" id="aochenBoard">
    <Separate title="在线留言" desc="ONLINE MESSAGE"></Separate>
    <div class="message-board-container-content">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名">
              <el-input v-model="form.linkUser" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="留言内容">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="form.memo"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-button type="danger" @click="handleSubmit" style="width: 600px;">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import Separate from '@/components/Separate/index'
import { siteLeaveAdd } from '@/api/MessageBoard'

export default {
  name: 'MessageBoard',
  components: { Separate },
  data () {
    return {
      form: {
        linkUser: undefined,
        phone: undefined,
        memo: undefined
      }
    }
  },
  methods: {
    handleSubmit () {
      siteLeaveAdd(this.form).then(({ data }) => {
        this.$message.success('提交成功，后续我们会根据您的意见给予您反馈！')
        this.form = {
          linkUser: undefined,
          phone: undefined,
          memo: undefined
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.message-board-container {
  width: $content-width;
  padding-bottom: 15px;
}
</style>
